import { template as template_c327ae1f28e744f3b99c452728b6147d } from "@ember/template-compiler";
const FKControlMenuContainer = template_c327ae1f28e744f3b99c452728b6147d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
