import { template as template_b29baac92f9841d7be9671c27f4bc7bb } from "@ember/template-compiler";
const SidebarSectionMessage = template_b29baac92f9841d7be9671c27f4bc7bb(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
